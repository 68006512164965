/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import { Upload } from 'tea-component';
import style from './index.module.scss';
import React, { useCallback } from 'react';

export default function FileUpload({
  value,
  name,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange,
  accept,
}: {
  value: string;
  name: string;
  onChange: (v: string | ArrayBuffer) => void;
  accept?: string[] | string;
}) {
  const beforeUpload = useCallback(
    (file: File) => {
      const render = new FileReader();
      render.onload = function (e) {
        if (e.target?.result) {
          // onChange('e.target.result');
          onChange(e.target.result);
        }
      };
      render.readAsText(file);
      return false;
    },
    [onChange],
  );
  return value ? (
    <div className={style.upload_c}>
      <div className={style.upload_bt}>
        <div className={style.isupload}>{name}已上传</div>
        <div onClick={() => onChange('')} className={style.delete}></div>
      </div>
    </div>
  ) : (
    <Upload beforeUpload={beforeUpload} accept={accept}>
      <div className={style.upload_c}>
        <div className={style.upload_bt}>
          <div className={style.file}></div>文件上传
        </div>
      </div>
    </Upload>
  );
}
