/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../list.module.scss';
import PageBox from '@src/components/ui/PageBox';
import { Form, Justify, Select, Table, TableColumn } from 'tea-component';
import SearchInput from '@components/ui/SearchInput';
import { UserItem } from '@src/models';
import { GetOrgList, GetUserList } from '@src/utils/apis';
import { useParams } from 'react-router-dom';
import { formatUnixTime } from '@src/utils/tools';
const { pageable, autotip } = Table.addons;

export default function Chain() {
  const { chainId } = useParams();
  const [value, setValue] = useState('');
  const [orgId, setOrgId] = useState('');
  const [options, setOptions] = useState<any[]>([]);
  const [list, setList] = useState<UserItem[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const getList = useCallback(() => {
    setIsLoading(true);
    GetUserList({
      ChainId: chainId,
      UserId: value,
      OrgId: orgId,
      Limit: pageSize,
      Offset: pageIndex - 1,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.GroupList) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [chainId, value, orgId, pageIndex, pageSize]);
  useEffect(() => {
    getList();
  }, [chainId, pageIndex, pageSize]);
  useEffect(() => {
    GetOrgList({
      ChainId: chainId,
      Offset: 0,
      Limit: 99999,
    }).then((res) => {
      if (res.GroupList) {
        setOptions(
          res.GroupList.map((item) => ({
            value: item.OrgId,
            text: item.OrgId,
          })),
        );
      }
    });
  }, []);
  const columns = useMemo<TableColumn[]>(
    () => [
      {
        key: 'UserId',
        header: '用户名称',
        align: 'left',
      },
      {
        key: 'Role',
        header: '用户角色',
        align: 'left',
      },
      {
        key: 'OrgId',
        header: '所属组织',
        align: 'left',
      },
      {
        key: 'Timestamp',
        header: '创建时间',
        align: 'left',
        render: ({ Timestamp }) => formatUnixTime(Timestamp),
      },
    ],
    [],
  );
  return (
    <PageBox title="用户列表">
      <Justify
        right={
          <>
            <Form className={liststyle.searchform} hideLabel={false} layout="inline">
              <Form.Item label="所属组织">
                <Select
                  options={options}
                  clearable
                  matchButtonWidth
                  appearance="button"
                  size="m"
                  onChange={(value) => setOrgId(value)}
                  value={orgId}
                />
              </Form.Item>
            </Form>
            <SearchInput
              placeholder="请输入用户名称搜索"
              onSubmit={getList}
              onChange={(value) => setValue(value)}
              value={value}
            />
          </>
        }
      />
      <div className={liststyle.table}>
        <Table
          style={{
            marginTop: 24,
          }}
          className={liststyle.table}
          compact={false}
          records={list}
          recordKey="a"
          bordered={true}
          disableTextOverflow={true}
          columns={columns}
          addons={[
            autotip({
              isLoading,
            }),
            pageable({
              recordCount,
              pageIndex,
              onPagingChange: (query) => {
                if (query?.pageIndex) {
                  setPageIndex(query.pageIndex);
                }
                if (query?.pageSize) {
                  setPageSize(query.pageSize);
                }
              },
            }),
          ]}
        />
      </div>
    </PageBox>
  );
}
