/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */
// import escape = CSS.escape;

export const transformBase64ToStr = (base: string): string => {
  try {
    const decode = atob(base);
    // const str = decodeURI(decode);
    const str = decodeURIComponent(escape(decode));
    return str;
  } catch (e) {
    return base;
  }
};
// export const transformBase64ToStr = (base: string): string => {
//   try {
//     const decode = atob(base);
//     const str = decodeURI(decode);
//     return str;
//   } catch (e) {
//     return base;
//   }
// };
