/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import { ResponseError } from './type';

// api汉语映射
const apiMap: {
  [key: string]: string;
} = {
  GetChainList: '获取链列表',
  Decimal: '获取数据统计',
  GetTransactionNumByTime: '获取交易统计',
  GetOrgList: '获取组织列表',
  GetUserList: '获取用户列表',
  GetNodeList: '获取节点列表',
  GetBlockList: '获取区块列表',
  GetTxList: '获取交易列表',
  GetContractList: '获取合约列表',
  GetBlockDetail: '获取区块详情',
  GetTxDetail: '获取交易详情',
  Search: '搜索',
  GetContractDetail: '获取合约详情',
  GetEventList: '获取事件列表',
  SubscribeChain: '订阅链',
  CancelSubscribe: '取消订阅',
  ModifySubscribe: '修改订阅',
};
// 错误信息映射
const errorMap: {
  [key: string]: string;
} = {
  'modification can not connect': '订阅连失败，请检查您所填写的信息是否正确再重试',
  'chain id already exists': '您输入的区块链id已订阅，请勿重复订阅',
  'can not connect chain': '订阅连失败，请检查您所填写的信息是否正确再重试',
};

export const transformDesc = (e: ResponseError): string => {
  const err = e.Error;
  if (err.Message && errorMap[err.Message]) {
    return errorMap[err.Message];
  }
  return `请求失败： ${err.Code}:${err.Message}`;
};

export const transformTitle = (en: string): string => {
  if (apiMap[en]) {
    return apiMap[en];
  }
  return en;
};
