/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import { ChangeContext, Modal } from 'tea-component';
import React from 'react';
import SubscribeForm from '@components/form/SubscribeForm';
import { Subscribe } from '@src/models';
import style from './index.module.scss';

export default function SubscribeFormModel({
  visible,
  onSubmit,
  onClose,
  initialData,
  title,
}: {
  visible: boolean;
  onSubmit: (value: Subscribe, context?: ChangeContext<React.SyntheticEvent<Element, Event>>) => void;
  onClose: () => void;
  initialData?: string;
  title?: string;
}) {
  // const [caption, setCaption] = useState('');
  // const caption = useMemo(() => {
  //   if (initialData) {
  //     // '修改订阅链信息';
  //     return title;
  //   }
  //   return '新增订阅链';
  // }, [initialData]);
  return (
    <Modal size={650} className={style.modal} visible={visible} caption={title} onClose={onClose}>
      <Modal.Body>
        <SubscribeForm
          onSubmit={onSubmit}
          onCancel={onClose}
          initialData={initialData}
          submitText="确认"
          cancelText="取消"
        />
      </Modal.Body>
    </Modal>
  );
}
