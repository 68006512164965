/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */
import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import Between from '@components/ui/Between';
import { BasicArea, DataSource } from 'tea-chart';
import { GetTransactionNumByTime } from '@src/utils/apis';
import { useParams } from 'react-router-dom';
import moment from 'moment';
export default function Chart() {
  const { chainId } = useParams();
  const [transactions, setTransactions] = useState<DataSource>([]);
  useEffect(() => {
    GetTransactionNumByTime({
      ChainId: chainId,
    }).then((res) => {
      if (res.GroupList) {
        const list: DataSource = res.GroupList.reverse().map((item) => ({
          time: moment.unix(item.Timestamp).format('HH:mm'),
          value: item.TxNum,
        }));
        setTransactions(list);
      }
    });
  }, [chainId]);
  return (
    <div className={style.chart}>
      <div className={style.chart_title}>
        <Between title="交易统计" content={<div className={style.chart_time}>近24小时</div>} />
      </div>
      <div className={style.chart_c}>
        <BasicArea
          smooth
          height={330}
          position="time*value"
          dataSource={transactions}
          tooltip={{
            enable: true,
            formatter: ([{ title, value }]) => `时间:${title} 交易数量${value}`,
          }}
        />
      </div>
    </div>
  );
}
