/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */
import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import group from '@imgs/group.png';
import user from '@imgs/user.png';
import height from '@imgs/height.png';
import deal from '@imgs/deal.png';
import contract from '@imgs/contract.png';
import node from '@imgs/node.png';
import { useNavigate, useParams } from 'react-router-dom';
import { Decimal } from '@src/utils/apis';
import { ChainInfo } from '@src/models';
export default function Statistics() {
  const { chainId } = useParams();
  const navigate = useNavigate();
  const [detail, setDetail] = useState<ChainInfo | null>(null);
  useEffect(() => {
    Decimal({
      ChainId: chainId,
    }).then((res) => {
      if (res.Data) {
        setDetail(res.Data);
      }
    });
  }, [chainId]);
  return (
    <div className={style.block}>
      <div className={style.block_i} onClick={() => navigate(`/${chainId}/block`)}>
        <div className={style.block_img}>
          <img src={height} />
        </div>
        <p className={style.block_count}>{detail?.BlockHeight}</p>
        <p className={style.block_name}>区块高度</p>
      </div>
      <div className={style.block_i} onClick={() => navigate(`/${chainId}/transaction`)}>
        <div className={style.block_img}>
          <img src={deal} />
        </div>
        <p className={style.block_count}>{detail?.TransactionNum}</p>
        <p className={style.block_name}>累计交易数</p>
      </div>
      <div className={style.block_i} onClick={() => navigate(`/${chainId}/contract`)}>
        <div className={style.block_img}>
          <img src={contract} />
        </div>
        <p className={style.block_count}>{detail?.ContractNum}</p>
        <p className={style.block_name}>累计合约数</p>
      </div>
      <div className={style.block_i} onClick={() => navigate(`/${chainId}/origin`)}>
        <div className={style.block_img}>
          <img src={group} />
        </div>
        <p className={style.block_count}>{detail?.OrgNum}</p>
        <p className={style.block_name}>组织数</p>
      </div>
      <div className={style.block_i} onClick={() => navigate(`/${chainId}/node`)}>
        <div className={style.block_img}>
          <img src={node} />
        </div>
        <p className={style.block_count}>{detail?.NodeNum}</p>
        <p className={style.block_name}>节点数</p>
      </div>
      <div className={style.block_i} onClick={() => navigate(`/${chainId}/user`)}>
        <div className={style.block_img}>
          <img src={user} />
        </div>
        <p className={style.block_count}>{detail?.UserNum}</p>
        <p className={style.block_name}>链上用户数</p>
      </div>
    </div>
  );
}
