/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import { ChainItem } from '@src/models';

export type ChainReducerState = ChainItem[] | null;

export const chainReducer = (
  state: ChainReducerState = null,
  action: {
    type: string;
    payload?: ChainItem[];
  },
): ChainReducerState => {
  if (action.type === 'UPDATE_CHAINS') {
    if (action.payload) {
      return [...action.payload];
    }
  }
  return state;
};
