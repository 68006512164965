/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../list.module.scss';
import PageBox from '@src/components/ui/PageBox';
import { Form, Justify, Select, Table, TableColumn } from 'tea-component';
import SearchInput from '@components/ui/SearchInput';
import { useParams } from 'react-router-dom';
import { NodeItem } from '@src/models';
import { GetNodeList, GetOrgList } from '@src/utils/apis';
import { formatAddress } from '@src/utils/tools';
const { pageable, autotip } = Table.addons;

export default function Chain() {
  const { chainId } = useParams();
  const [value, setValue] = useState('');
  const [orgId, setOrgId] = useState('');
  const [options, setOptions] = useState<any[]>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<NodeItem[]>([]);
  const getList = useCallback(() => {
    setIsLoading(true);
    GetNodeList({
      ChainId: chainId,
      Offset: pageIndex - 1,
      Limit: pageSize,
      NodeName: value,
      OrgId: orgId,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.GroupList) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [chainId, orgId, value, pageIndex, pageSize]);
  useEffect(() => {
    getList();
  }, [pageIndex, chainId, pageSize]);
  useEffect(() => {
    GetOrgList({
      ChainId: chainId,
      Offset: 0,
      Limit: 99999,
    }).then((res) => {
      if (res.GroupList) {
        setOptions(
          res.GroupList.map((item) => ({
            value: item.OrgId,
            text: item.OrgId,
          })),
        );
      }
    });
  }, []);
  const columns = useMemo<TableColumn[]>(
    () => [
      {
        key: 'NodeId',
        header: '节点Id',
        align: 'left',
      },
      {
        key: 'NodeName',
        header: '节点名称',
        align: 'left',
      },
      {
        key: 'NodeAddress',
        header: '节点IP和端口',
        align: 'left',
        render: ({ NodeAddress }) => formatAddress(NodeAddress),
      },
      {
        key: 'Role',
        header: '节点角色',
        align: 'left',
        width: 160,
      },
      {
        key: 'OrgId',
        header: '所属组织',
        align: 'left',
      },
    ],
    [],
  );
  return (
    <PageBox title="节点列表">
      <Justify
        right={
          <>
            <Form className={liststyle.searchform} hideLabel={false} layout="inline">
              <Form.Item label="所属组织">
                <Select
                  options={options}
                  clearable
                  matchButtonWidth
                  appearance="button"
                  size="m"
                  onChange={(value) => setOrgId(value)}
                  value={orgId}
                />
              </Form.Item>
            </Form>
            <SearchInput
              placeholder="请输入节点名称搜索"
              onSubmit={getList}
              onChange={(value) => setValue(value)}
              value={value}
            />
          </>
        }
      />
      <div className={liststyle.table}>
        <Table
          style={{
            marginTop: 24,
          }}
          className={liststyle.table}
          compact={true}
          records={list}
          recordKey="a"
          bordered={true}
          disableTextOverflow={true}
          columns={columns}
          addons={[
            autotip({
              isLoading,
            }),
            pageable({
              recordCount,
              pageIndex,
              onPagingChange: (query) => {
                if (query?.pageIndex) {
                  setPageIndex(query.pageIndex);
                }
                if (query?.pageSize) {
                  setPageSize(query.pageSize);
                }
              },
            }),
          ]}
        />
      </div>
    </PageBox>
  );
}
