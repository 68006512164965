/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import Between from '@components/ui/Between';
import { Tx } from '@src/models';
import { GetTxList } from '@src/utils/apis';
import { Link, useParams } from 'react-router-dom';
import { formatUnixWithoutYear } from '@src/utils/tools';
import { Bubble, Icon } from 'tea-component';

export default function Deal() {
  const { chainId } = useParams();
  const [txs, setTxs] = useState<Tx[]>([]);
  useEffect(() => {
    GetTxList({
      ChainId: chainId,
      Limit: 10,
    }).then((res) => {
      if (res.GroupList) {
        setTxs(res.GroupList);
        // setTxs([]);
      }
    });
  }, [chainId]);
  return (
    <div className={style.box}>
      <Between
        title={
          <>
            最新交易
            <Bubble arrowPointAtCenter placement="top-start" content="表示该笔交易是哪个链账户调用哪个智能合约产生的。">
              <Icon type="info" />
            </Bubble>
          </>
        }
        content={
          <Link className={style.more} to={`/${chainId}/transaction`}>
            更多&gt;
          </Link>
        }
      />
      <div className={style.list}>
        {txs.length !== 0 &&
          txs.map(({ Id, Sender, ContractName, Timestamp, TxId }) => (
            <div key={Id} className={style.item}>
              <div className={`${style.title} single_line`}>
                <Link to={`/${chainId}/transaction/${TxId}`}>{TxId}</Link>
              </div>
              <div className={style.info}>
                <div className={style.left}>
                  <div className={style.path}>
                    <Bubble content={Sender}>{Sender}</Bubble>
                  </div>
                  <div className={style.point}></div>
                  <div className={style.point}></div>
                  <div className={style.point}></div>
                  <div className={style.name}>
                    <Bubble content={ContractName}>{ContractName}</Bubble>
                  </div>
                </div>
                <div className={style.time}>{formatUnixWithoutYear(Timestamp)}</div>
              </div>
            </div>
          ))}
        {txs.length === 0 && <div className="empty_list"></div>}
      </div>
    </div>
  );
}
